import React from 'react';
import GLOBALS from '../globals'
import Visualiser from './visualiser';
import Controller from './controller';
import SCENES from './scenes/scenes';

// Event Streams
import vS from './control_flow/visualiser_event_stream';
import pS from './control_flow/playback_event_stream';
import gS from './control_flow/global_event_stream';


export default class Running extends React.Component {
  constructor(props) {
      // Receives only session id. Initiates running lifecycle.
    super(props);
    this.initScene = 'ec';
    this.session_id = this.props.session_id;

    // Initialize Event Streams
    this.visualiserStream = new vS();
    this.playbackStream = new pS(this.session_id);
    this.globalStream = new gS(this.session_id);
    this.state = this.globalStream.initStream();
  };

  componentDidUpdate() {
    console.log("ComponentDidUpdate!")
  };

  componentDidMount() {
    this.initListeners();
    this.visualiserStream.startStream(this.initScene);
    this.playbackStream.startStream();
  };

  initListeners() {
    this.playbackStream.listener(this.globalStream.pushObj());
    this.visualiserStream.listener(this.globalStream.pushObj()); 
    this.globalStream.listener(this.updateState.bind(this));
  };

  updateState(state) {
    console.log(state)
    this.setState(state)
  }

  changeVisualiser(id) {
    this.visualiserStream.startStream(id);
  };

  render () {
    if (this.state.playback === true) {
        return (
            <div>
                <Controller content={{
                    "state": this.state,
                    "play_object": this.state.playObj.play_object,
                    "scenes": SCENES,
                    "active_scene": this.state.visObj.scene_id,
                    "quality_level": this.state.visObj.quality_level,
                    "callback": this.changeVisualiser.bind(this)
                    }} />
                <Visualiser content={{
                    "play_object": this.state.playObj.play_object,
                    "analysis_object": this.state.analysisObj,
                    "scene_resources": this.state.visObj.scene_resources,
                    "quality_level": this.state.visObj.quality_level,
                    "scene_blueprint": SCENES[this.state.visObj.scene_id],
                    "key": this.state.id,
                    }} />
            </div>
        )
    }
    return (
        <Controller content={{
            "state": this.state,
            "play_object": this.state.playObj.play_object,
            "scenes": null,
            "active_scene": null
            }} />
    )
  };
};