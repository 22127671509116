import GLOBALS from '../globals'

const default_status = {
    "top": "",
    "main": "Loading...",
    "bottom": "",
    "button_active": false,
    "button_display": "loading",
};


function determineStatus(content) {
    let status = {...default_status};
    if (content.state.playback === true) {
        status.top = content.play_object.item.artists[0].name;
        status.main = content.play_object.item.name;
        if (content.state.visObj.quality_level == 0) {
            status.bottom = "Loading...";
            return status;
        }
        else {
            status.button_active = true;
            status.button_display = "lines";
    
            const scene = content.scenes[content.active_scene].title
            const ql = GLOBALS["quality_levels"][content.quality_level].name
            const description = `${scene} [${ql}p]`
            status.bottom = description;
            return status;
        };
    }
    else if (content.state.playObj.label != "loading") {
        switch (content.state.playObj.label) {
            case "no_session":
                status.main = "Play a song on Spotify!";
                status.button_display = "pause";
                return status;
            case "song_paused":
                status.top = "Oh la la";
                status.main = "Song paused";
                status.button_display = "pause";
                return status;
            case "error_occured":
                status.top = "Woopsie Doopsie";
                status.main = "Something went wrong";
                status.bottom = "You can try refreshing the page, sometimes it helps.";
                status.button_display = "error";
                return status;
            default:
                return status;
        };
    }
    else {
        return status;
    };
};

export default determineStatus;
