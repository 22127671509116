import moLib from '../motion_library'
import ryLib from '../rythm_library'

const ml = new moLib()
const rl = new ryLib();


const shadow = (f) => {
    if (f.track.tempo < 100) {
        const rythm_object = [
            [0,1],
            [0,1]
        ]
        const rythm = ml.rythm(f.bar.position,rythm_object)
        return rythm
    }
    else {
        return f.bar.position;
    }
}

const levels = [
    {
        "loudness": 0.25,
        "rythm": [1],
    },
    {
        "loudness": 0.75,
        "rythm": [1,3,2,3,2],
    },
    {
        "loudness": 1,
        "rythm": [4,5,6,7],
    },

];

const background = {
    "id": "root",
    "branch": "select",
    "selector": rl.select_loudness,
    "select": [
        {
            "id": "quiet",
            "value": 0.2,
            "branch": "rythm",
            "rythm": [
                {"id": "red", "branch": null},
                {"id": "blue", "branch": null},
            ],
        },
        {
            "id": "cresc",
            "value": 0.8,
            "branch": "rythm", 
            "rythm": [
                {
                    "id": "yes",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "red", "branch": null},
                        {"id": "blue", "branch": null},
                    ],
                },
                {
                    "id": "na",
                    "branch": null,
                },
                {
                    "id": "yes",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "red", "branch": null},
                        {"id": "blue", "branch": null},
                    ],
                },
            ],
        },
        {
            "id": "drop",
            "value": 1,
            "branch": "rythm",
            "rythm": [
                {"id": "pulsepolice", "branch": null},
                {"id": "pulsepink", "branch": null},
                {"id": "pulsepolice", "branch": null},
                {"id": "pulsepink", "branch": null},
            ],
        },
    
    ]
};


const foreground = {
    "id": "root",
    "branch": "select",
    "selector": rl.select_loudness,
    "select": [
        {
            "id": "quiet",
            "value": 0.2,
            "branch": null,
        },
        {
            "id": "cresc",
            "value": 0.8,
            "branch": "rythm", 
            "rythm": [
                {
                    "id": "car",
                    "branch": "select",
                    "selector": rl.select_loudness,
                    "select": [
                        {"id": "1","branch": null,"value": 0.6},
                        {"id": "2","branch": null,"value": 0.8},
                    ],
                },
                {
                    "id": "panther",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "1","branch": null},
                        {"id": "2","branch": null},
                        {"id": "3","branch": null},
                        {"id": "4","branch": null},
                    ],
                },
                {
                    "id": "diamond",
                    "branch": "select",
                    "selector": rl.select_loudness,
                    "select": [
                        {"id": "1","branch": null,"value": 0.4},
                        {"id": "2","branch": null,"value": 0.6},
                        {"id": "3","branch": null,"value": 0.8},
                    ],
                },
            ],
        },
        {
            "id": "drop",
            "value": 1,
            "branch": "rythm",
            "rythm": [
                {
                    "id": "handcuffs",
                    "branch": "rythm",
                    "rythm": [
                        {
                            "id": "small",
                            "branch": null,
                        },
                        {
                            "id": "big",
                            "branch": null,
                        }
                    ],
                },
                {
                    "id": "fluffcuffs",
                    "branch": "rythm",
                    "rythm": [
                        {
                            "id": "small",
                            "branch": null,
                        },
                        {
                            "id": "big",
                            "branch": null,
                        }
                    ],
                },
                {
                    "id": "spymask",
                    "branch": "rythm",
                    "rythm": [
                        {
                            "id": "small",
                            "branch": null,
                        },
                        {
                            "id": "big",
                            "branch": null,
                        }
                    ],
                },
                {
                    "id": "silkmask",
                    "branch": "rythm",
                    "rythm": [
                        {
                            "id": "small",
                            "branch": null,
                        },
                        {
                            "id": "big",
                            "branch": null,
                        }
                    ],
                },

            ],
        },
    
    ]
};


const pp = {

    "id": "pp",
    "title": "'69 Panther Mobile",
    "description": "In 1969, the Americans built a car to promote the Pink Panther cartoon show. Today, we look back at the Pink Panther as one of the greatest cultural phenomena of the last century. This visualiser pays homage to the lasting legacy of this laid-back, jazz-loving big cat.",
    "pseudo_description": "A vibrant visualiser, medium-bodied with flavours of astrophysics, followed by a soft finish.",
    "engine": "default",
    "vistruments": [
        {"id": "blue_bg", "dimensions": [2000, 2000], "count": 60, "alpha": false},
        {"id": "car_back", "dimensions": [2000, 2000], "count": 50, "alpha": true},
        {"id": "car_front", "dimensions": [2000, 2000], "count": 50, "alpha": true},
        {"id": "diamond", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "fluff_cuffs", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "hand_cuffs", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "pink_bg", "dimensions": [2000, 2000], "count": 60, "alpha": false},
        {"id": "pulse_pink", "dimensions": [2000, 2000], "count": 60, "alpha": false},
        {"id": "pulse_police", "dimensions": [2000, 2000], "count": 60, "alpha": false},
        {"id": "red_bg", "dimensions": [2000, 2000], "count": 60, "alpha": false},
        {"id": "shadow_1", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "shadow_2", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "shadow_3", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "shadow_4", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "silk_mask", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "spy_mask", "dimensions": [2000, 2000], "count": 60, "alpha": true},
        {"id": "stars", "dimensions": [2000, 2000], "count": 60, "alpha": true},
    ],
    "instances": [

        // Car Scene
        {
            "id": "red_bg",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["quiet", "red"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "blue_bg",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["quiet", "blue"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "car_back",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["quiet"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "car_front",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => (1 + Math.sin(2 * Math.PI * shadow(f)) )/2,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["quiet"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        // Cresc BG
        {
            "id": "red_bg",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["cresc", "yes", "red"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "blue_bg",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["cresc", "yes", "blue"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        // Car
        {
            "id": "car_back",
            "scale": (f) => 1.25,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "car", "1"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "car_front",
            "scale": (f) => 1.25,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => (1 + Math.sin(2 * Math.PI * shadow(f)) )/2,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "car", "1"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        {
            "id": "car_back",
            "scale": (f) => 2,
            "opacity": null,
            "position": (f) => [750,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "car", "2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "car_front",
            "scale": (f) => 2,
            "opacity": null,
            "position": (f) => [750,500],
            "state": (f) => (1 + Math.sin(2 * Math.PI * shadow(f)) )/2,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "car", "2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },


        // Diamond

        {
            "id": "diamond",
            "scale": (f) => 0.5 + 0.1 * f.beat.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "diamond", "1"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "diamond",
            "scale": (f) => 0.5 + 0.1 * f.beat.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "diamond", "2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "diamond",
            "scale": (f) => 0.2 + 0.1 * f.beat.position,
            "opacity": null,
            "position": (f) => [770,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "diamond", "2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "diamond",
            "scale": (f) => 0.2 + 0.1 * f.beat.position,
            "opacity": null,
            "position": (f) => [230,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "diamond", "2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "diamond",
            "scale": (f) => 1 + 0.2 * f.beat.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "diamond", "3"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },


        // Panther Dancing
        {
            "id": "pink_bg",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "panther"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "stars",
            "scale": (f) => 2,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "panther"]),
            "rotate": (f) => 0.5 * ml.smoothSource(f,3,"raw"),
            "filter": (f) => {
                let opacity = Math.round(f.segment.current.loudness_stable*100)
                let filter = `brightness(200%) opacity(${opacity}%)`
                return filter;
            },
        },
        {
            "id": "shadow_1",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "panther", "1"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shadow_2",
            "scale": (f) => 1.2,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "panther", "2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shadow_3",
            "scale": (f) => 0.5 + 0.3 * f.bar.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "panther", "3"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shadow_4",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "panther", "4"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        // Drop BG
        {
            "id": "pulse_pink",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["drop", "pulsepink"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "pulse_police",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["drop", "pulsepolice"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        // Fluff Cuffs
        {
            "id": "fluff_cuffs",
            "scale": (f) => 0.5 + ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "fluffcuffs", "small"]),
            "rotate": (f) => 0.1 * ml.smoothSource(f,1,"raw"),
            "filter": (f) => null,
        },
        {
            "id": "fluff_cuffs",
            "scale": (f) => 0.8 + ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "fluffcuffs", "big"]),
            "rotate": (f) => 0.1 * ml.smoothSource(f,1,"raw"),
            "filter": (f) => null,
        },

        // Hand Cuffs
        {
            "id": "hand_cuffs",
            "scale": (f) => 0.5 + ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "handcuffs", "small"]),
            "rotate": (f) => 0.1 * ml.smoothSource(f,1,"raw"),
            "filter": (f) => null,
        },
        {
            "id": "hand_cuffs",
            "scale": (f) => 0.8 + ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "handcuffs", "big"]),
            "rotate": (f) => 0.1 * ml.smoothSource(f,1,"raw"),
            "filter": (f) => null,
        },

        // Silk Mask
        {
            "id": "silk_mask",
            "scale": (f) => 1 + f.bar.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => {
                if (f.track.tempo < 140) {
                    const rythm_object = [
                        [0,1],
                        [0,1]
                    ]
                    const rythm = ml.rythm(f.bar.position,rythm_object)
                    return rythm
                }
                else {
                    return f.bar.position;
                }
            },
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "silkmask", "small"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "silk_mask",
            "scale": (f) => 1.5 + f.bar.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => {
                if (f.track.tempo < 140) {
                    const rythm_object = [
                        [0,1],
                        [0,1]
                    ]
                    const rythm = ml.rythm(f.bar.position,rythm_object)
                    return rythm
                }
                else {
                    return f.bar.position;
                }
            },
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "silkmask", "big"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        // Spy Mask
        {
            "id": "spy_mask",
            "scale": (f) => 1 + f.bar.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => {
                if (f.track.tempo < 140) {
                    const rythm_object = [
                        [0,1],
                        [0,1]
                    ]
                    const rythm = ml.rythm(f.bar.position,rythm_object)
                    return rythm
                }
                else {
                    return f.bar.position;
                }
            },
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "spymask", "small"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "spy_mask",
            "scale": (f) => 1.5 + f.bar.position,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => {
                if (f.track.tempo < 140) {
                    const rythm_object = [
                        [0,1],
                        [0,1]
                    ]
                    const rythm = ml.rythm(f.bar.position,rythm_object)
                    return rythm
                }
                else {
                    return f.bar.position;
                }
            },
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop", "spymask", "big"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
    ]
}

export default pp;