import React from 'react';
import GLOBALS from '../globals'
import style from './styles/login.module.css'

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {default_state: null};
  }

  makeRequest() {
    const base = "https://accounts.spotify.com/authorize"
    const response_type = "code"
    const client_id = GLOBALS["client_id"]
    const redirect_uri = GLOBALS["callback"]
    const scope = GLOBALS["scope"]

    const string = 
    `${base}?client_id=${client_id}&response_type=${response_type}&redirect_uri=${redirect_uri}&scope=${scope}`

    return string
  }; 

  render () {

    const w = window.innerWidth;
    const h = window.innerHeight;
    const desktop = w > h ? true : false;
    const device_style = desktop ? style.global_desktop : style.global_mobile;

    return (
      <div className={device_style}>
          <div className={style.title}>Unpredictable Tingles</div>

          <div className={style.box}>
            <div className={style.intro}>
              Login with spotify to continue.
            </div>
            <a className={style.button_box} href={this.makeRequest()}>
              <div className={style.button}>Let's Go</div>
            </a>
          </div>
          <div className={style.pseudo_box}></div>
      </div>


    )
  };
}