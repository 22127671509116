import GLOBALS from '../globals'
import ml from 'machine_learning'


class processAnalysis {

    constructor() {
    };

    normalize(array) {
        const min = Math.min(...array);
        const max = Math.max(...array);

        if (min === 0) {
            return array.map(x => x / max);
        }
        else if (min > 0) {
            return array.map(x => (x - min) / (max - min));
        }
        else {
            return array.map(x => (x + Math.abs(min)) / (max + Math.abs(min)))
        }
    };


    smooth(array,degree) {
        // Simple running average implementation.
        return array.map((x,i) => {
            const start = i > degree ? i - degree : 0;
            const end = i < array.length - degree ? i + degree : array.length;
            const current = array.slice(start,end)
            const average = current.reduce((t,x) => t + x) / current.length;
            return average;
        })
    
    };


    process(analysis_object) {
        // Takes analysis object from spotify;
        // Returns processed analysis_object
        const processed = {
            "bars": this.bars(analysis_object["bars"]),
            "beats": analysis_object["beats"],
            "tatums": analysis_object["tatums"],
            "sections": this.sections(analysis_object["sections"]),
            "segments": this.segments(analysis_object["segments"]),
            "track": analysis_object["track"],
            "sources": this.sourceSep(analysis_object["segments"]),
        }
        //console.log(processed)
        return processed
    };

    bars(bars) {
        const confidence = this.smooth(bars.map(x => x.confidence),2)
        for (var i = 0; i<bars.length; i++) {
            bars[i].confidence = confidence[i];
        };
        return bars;
    };

    sections(sections) {
        const tempo = this.normalize(sections.map(x => x.tempo))
        for (var i = 0; i<sections.length; i++) {
            sections[i].tempo = tempo[i]
        };
        return sections;
    };

    sigmoid(loudness) {
        const average = loudness.reduce((t,n) => t + n) / loudness.length;
        const louder = loudness.map(x => x > average ? (x - average) / (1 - average) : 0);
        const quieter = loudness.map(x => x < average ? (x - average) / average : 0);
        const result = this.normalize(louder.map((x,i) => (x + quieter[i]) * 2 - 1));
        const sigmoid = result.map(x => 1 / (1 + Math.exp(-1.4 * x)))
        return this.normalize(sigmoid)
    }

    segments(segments) {
        const makeSigmoid = loudness => {
            const average = loudness.reduce((t,n) => t + n) / loudness.length;
            const louder = loudness.map(x => x > average ? (x - average) / (1 - average) : 0);
            const quieter = loudness.map(x => x < average ? (x - average) / average : 0);
            const result = this.normalize(louder.map((x,i) => (x + quieter[i]) * 2 - 1));
            const sigmoid = result.map(x => 1 / (1 + Math.exp(-1.4 * x)))
            return this.normalize(sigmoid)
        };

        const smooth_degree = 20
        const loudness_max = this.normalize(segments.map(x => x.loudness_max));
        const loudness_start = this.normalize(segments.map(x => x.loudness_start));
        const loudness_max_stable = this.normalize(this.smooth(loudness_max,smooth_degree));
        const sigmoid = makeSigmoid(loudness_max_stable)

        for (var i = 0; i < segments.length; i++) {
            segments[i].loudness_max = loudness_max[i];
            segments[i].loudness_start = loudness_start[i];
            segments[i].loudness_stable = loudness_max_stable[i];
            segments[i].sigmoid = sigmoid[i];
        };

        return segments;
    };



    sourceSep(segments) {

        let sources = []
        const new_sources = this.sourceSepAdv(segments)
        for (var i = 0; i < segments.length; i++) {
            sources[i] = {}
            sources[i].start = segments[i].start;
            sources[i].duration = segments[i].duration;
            sources[i].raw = new_sources.raw[i];
            sources[i].smooth = new_sources.smooth[i];
        };
        return sources;

    };

    sourceSepAdv(segments) {
        let sources = []

        const pitches = segments.map(x => x.pitches);
        const timbre = segments.map(x => this.normalize(x.timbre));
        const duration = this.normalize(segments.map(x => x.duration));
        const loudness = this.normalize(segments.map(x => x.loudness_max));
        
        const spectrogram = pitches.map((x,i) => [...x,...timbre[i],...[duration[i]],...[loudness[i]]]);

        const rank = 3;
        const W = this.nmf(spectrogram,rank);

        const raw = W => {
            W = this.transpose(W)
            for (var i = 0; i < rank; i++) {
                W[i] = this.normalize(W[i]);
            }
            return this.transpose(W);
        };

        const smoothed = W => {
            W = this.transpose(W)
            const degree = 10;
            for (var i = 0; i < rank; i++) {
                W[i] = this.normalize(this.smooth(W[i],degree));
            }
            return this.transpose(W);
        };
        
        return {"raw": raw(W), "smooth": smoothed(W)};
    }

    transpose(matrix) {
        const dim_1 = matrix.length;
        const dim_2 = matrix[0].length;
        let output = []
        for (let i = 0; i < dim_2; i++) {
            output.push(matrix.map(x => x[i]))
        }      
        return output;
    }

    nmf(matrix,rank) {
        const W = ml.nmf.factorize({
            matrix : matrix,
            features: rank,
            epochs: 80,
        })[0];
        return W;
    };




};


export default processAnalysis;