import GLOBALS from '../globals'

class Diplomat {

    constructor() {
        this.access = null;
    };

    async getAccess(session_id) {
        // fetch access code from backend.
        // Return Access Code
        const params = new URLSearchParams()
        params.append("session_id",session_id)
        const url = `${GLOBALS["getAccess"]}?${params.toString()}`
        const response = await fetch(url);
        const json = await response.json();
        return json["access"];

    };

    async refreshAccess(session_id) {
        // if found that code has expired, get refreshed access code from backend.
        // Return Access Code
        const params = new URLSearchParams()
        params.append("session_id",session_id) // This might cause us some lil problems. WASNT THAT RIGHT?
        const url = `${GLOBALS["refreshAccess"]}?${params.toString()}`
        const response = await fetch(url);
        const json = await response.json();
        if ("error" in json) {
            return console.log("Refresh Token has expired. Prompt new login here.")
        }
        return json["access"]
        // Here we need to check for possible error from the server side before returning the json.

    };


    async getPlay(session_id) {
        // Get Playback Information
        // Return Play Object.
            // Case 1: Active -> Start up visualiser.
            // Case 2: Inactive -> Prompt action.
            // Case 3: Error ->  Display error information.

        // Case 1: Valid access code.
        // Case 2: Expired access code.
        // Case 3: No access at the minute. Force new login.

        const fetchPlay = async(repeat=null) => {
            const url = "https://api.spotify.com/v1/me/player/currently-playing"
            const headers = new Headers({
                "Authorization": `Bearer ${this.access}`
            });
            const response = await fetch(url,{"headers": headers});
            if (response.status != 200) {
                if (response.status == 401) {
                    if (repeat) {
                        console.log(response)
                        return "error_occurred";
                    }
                    this.access = await this.refreshAccess(session_id);
                    return fetchPlay(true);
                }
                if (response.status == 204) {
                    return "no_session" 
                }
                return "error_occurred"
            };
            const json = await response.json(); 
            //console.log(json)
            return json; 
        }

        // Retrieve access code, try to get play object.
        this.access = this.access === null ? await this.getAccess(session_id) : this.access;
        //console.time();
        const output = fetchPlay();
        //console.timeEnd();
        return output;
    };

    async getAnalysis(session_id,song_id) {
        // Returns analysis object
        const fetchAnalysis = async(repeat=null) => {
            const url = `https://api.spotify.com/v1/audio-analysis/${song_id}`
            const headers = new Headers({
                "Authorization": `Bearer ${this.access}`
            });
            const response = await fetch(url,{"headers": headers});
            if (response.status != 200) {
                if (response.status == 401) {
                    if (repeat) {
                        console.log(response);
                        return "unauthorized";
                    }
                    this.access = await this.refreshAccess(session_id);
                    return fetchAnalysis(true);
                }
                return "unknown_error"
            };
            const json = await response.json();
            return json; 
        }

        // Retrieve access code, try to get play object.
        this.access = this.access === null ? await this.getAccess(session_id) : this.access;
        return fetchAnalysis();
    };

    handleResponse(play_object) {
        // There is no active session.
        // We suggest to stop playback or change message.
        if (play_object["active_session"] == false) {
            return this.suggestState({
                "render_canvas": false,
                "song_info": null,
                "message": "No active Spotify Session on your account."
            })
        }
        // The current song is paused.
        // We suggest to stop playback or change message.
        if (play_object["is_playing"] == false) {
            return this.suggestState({
                "render_canvas": false,
                "song_info": null,
                "message": "Current song has been paused."
            })       
        }
        // A song is playing.
        // It might have changed, we might not be in sync.
        // We suggest an update to the current state.
        return this.suggestState({
            "render_canvas": true,
            "song_info": this.processSongInfo(play_object),
            "message": null
        })
      }

};


export default Diplomat;