import moLib from '../motion_library'

const ml = new moLib()

class spec {
    // These are not designed to be reused outside of a group of instances;
    static quietLoud(f,range) {

        const ranges = {
            "loud": [0.8,1],
            "omni": [0.25,0.8],
            "quiet": [0,0.25],
        }
        const loudness = f.segment.current.sigmoid
        if (loudness >= ranges[range][0] && loudness <= ranges[range][1]) {
            return false;
        }
        return true
    }



};


const ltsr = {

    "id": "ltsr",
    "title": "Lovely Thing Suite: Roses",
    "description": "A vibrant visualiser, medium-bodied with flavours of astrophysics, followed by a soft finish. Delicious with game.",
    "engine": "default",
    "vistruments": [
        {
            "id": "saxophone",
            "dimensions": [1200,800],
            "count": 47,
            "mask": true
        },
        {
            "id": "harp",
            "dimensions": [1200,800],
            "count": 47,
            "mask": true
        },
        {
            "id": "rose",
            "dimensions": [600,600],
            "count": 49,
            "mask": true
        },
        {
            "id": "rose_flip",
            "dimensions": [600,600],
            "count": 49,
            "mask": true
        },
        {
            "id": "rocket",
            "dimensions": [640,480],
            "count": 60,
            "mask": false
        },
        {
            "id": "slide",
            "dimensions": [1200,800],
            "count": 49,
            "mask": false
        },
    ],
    "instances": [


        // QUIET

        {
            "id": "slide",
            "scale": (f) => 1.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"quiet"),
            "rotate": (f) => null,
        },

        // MID RANGE
        {
            "id": "rocket",
            "scale": (f) => 3,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => 0.2 * f.beat.position + 0.8 * f.segment.current.loudness_stable,
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => 0.07 * f.segment.current.loudness_stable,
        },

        {
            "id": "rose_flip",
            "scale": (f) => 1.2,
            "opacity": null,
            "position": (f) => [640,680],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => ml.rotInit(0.8,0.05*ml.smoothSource(f,2,"raw")),
        },

        {
            "id": "slide",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => null,
        },

        {
            "id": "rose",
            "scale": (f) => 0.7,
            "opacity": null,
            "position": (f) => [350,400],
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => ml.rotInit(0.8,0.05 *ml.smoothSource(f,0,"raw")),
        },

        {
            "id": "saxophone",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => null,
        },

        {
            "id": "harp",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => 0.3 * f.tatum.position + 0.7 * ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => null,
        },

        {
            "id": "rose",
            "scale": (f) => 1.2,
            "opacity": null,
            "position": (f) => [150,700],
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => ml.rotInit(0.8,0.05 *ml.smoothSource(f,1,"raw")),
        },

        {
            "id": "rose_flip",
            "scale": (f) => 0.7,
            "opacity": null,
            "position": (f) => [850,300],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => ml.rotInit(0.8,0.05 *ml.smoothSource(f,2,"raw")),
        },

        // LOUD

        {
            "id": "rocket",
            "scale": (f) => 3 + f.beat.position * f.segment.current.loudness_stable,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => 0.2 * f.beat.position + 0.8 * f.segment.current.loudness_stable,
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"loud"),
            "rotate": (f) => 0.07 * f.segment.current.loudness_stable,
        },

        {
            "id": "rose",
            "scale": (f) => 1.8,
            "opacity": null,
            "position": (f) => [300,800],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"loud"),
            "rotate": (f) => ml.rotInit(0.8,0.05 *ml.smoothSource(f,2,"raw")),
        },

        {
            "id": "rose_flip",
            "scale": (f) => 1.8,
            "opacity": null,
            "position": (f) => [700,200],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"loud"),
            "rotate": (f) => ml.rotInit(0.8,0.05 *ml.smoothSource(f,2,"raw")),
        },




    




    ]
}

export default ltsr;