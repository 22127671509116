
import device from '../determine_device';
import getFrames from '../get_frames'
import SCENES from '../scenes/scenes';
import GLOBALS from '../../globals'


class visualiserStream {

    constructor() {
    }

    listener(func) {
        const f = (function (e) {
            //console.log(this.visObj);
            func(e.detail);
        }).bind(this);
        return window.addEventListener('vStream', f)
    };

    startStream(scene_id) {
        this.key = scene_id;
        this.Stream(scene_id, 0);
        const visObj = {
            "type": "visObj",
            "playback": false,
            "scene_id": "loading",
            "scene_resources": null,
            "quality_level": "none",
        }
        const event = new CustomEvent('vStream', {"detail": visObj});
        return window.dispatchEvent(event);   
    };

    async Stream(scene_id, ql) {
        // Load Resources
        console.log(ql)
        const scene_resources = await getFrames(SCENES[scene_id],ql);

        // Check Resources
        if (this.key == scene_id) {
            if (this.quality_potential(ql)) {
                this.Stream(scene_id,ql+1);
            }
            const visObj = {
                "type": "visObj",
                "playback": true,
                "scene_id": scene_id,
                "scene_resources": scene_resources,
                "quality_level": ql,
            };
            const event = new CustomEvent('vStream', {"detail": visObj});
            return window.dispatchEvent(event);   
        };
    };

    quality_potential(quality_level) {
        let potential = quality_level < GLOBALS["quality_levels"].length - 1 ? true : false;
        if (device() === "mobile") {
            potential = GLOBALS["quality_levels"][quality_level].name == "500" ? false : true;
        };       
        return potential;
    };
};

export default visualiserStream;