import GLOBALS from '../globals'


class drawFrame {

    constructor(blueprint,resources,canvas) {
        this.scene = {
            "id": blueprint.id,
            "engine": blueprint.engine,
            "vistruments": blueprint.vistruments,
            "instances": blueprint.instances,
            "resources": resources,
        }
        this.ctx = canvas.getContext("2d");
    }

    draw(frame_object,quality_level) {

        quality_level = GLOBALS["quality_levels"][quality_level]
        this.ctx.clearRect(0, 0, 2000, 2000);
        this.ctx.beginPath();
        this.ctx.rect(0,0,2000,2000);
        this.ctx.fillStyle = "black";
        this.ctx.fill();
        let preview = false;
        if (quality_level["name"] === "LOAD") {
            preview = true;
        }

        const instances = this.scene.instances;
        for (var i=0; i<instances.length;i++) {
            this.drawInstance(frame_object,instances[i],quality_level["quot"],preview)
        };

        
    
        return;
    }

    getImage(value,vistrument) {
        const img_array = this.scene.resources
        const toId = (norm) => {
            const index = Math.round(norm*(vistrument.count-1))
            const id = `${this.scene.id}_${vistrument.id}_${index}`
            return id
        }
        const id = toId(value)
        const cond = (img) => img.id == id;
        return img_array.find(cond)
    }

    drawInstance(frame_object,instance,quot,preview) {
        // Optional. Question of how order should work in scene file.
        // this.ctx.globalCompositeOperation = "destination-over"

        if (instance.hide(frame_object)) {
            return
        }
        const vistrument = ((ins) => {
            const cond = vis => vis.id == ins.id;
            return this.scene.vistruments.find(cond)
        })(instance)

        const rot = instance.rotate(frame_object)
        const state = instance.state(frame_object);
        let image = this.getImage(state,vistrument);
        //console.log(`${state} + ${vistrument.id}`)
        const pos = instance.position(frame_object);
        pos[0] = pos[0] * 2;
        pos[1] = pos[1] * 2;
        const scale = instance.scale(frame_object);
        const width = scale*vistrument.dimensions[0];
        const height = scale*vistrument.dimensions[1];
        const x_pos = pos[0] - (width / 2);
        const y_pos = pos[1] - (height / 2);
        const filter = preview ? "blur(50px)" : instance.filter(frame_object);

        if(filter != null) {
            this.ctx.filter = filter;
        }

        if(rot != null) {
            this.ctx.translate(pos[0], pos[1]);
            this.ctx.rotate(rot * Math.PI * 2);
            this.ctx.drawImage(image,-width/2,-height/2,width,height);
            this.ctx.setTransform(1, 0, 0, 1, 0, 0)
            this.ctx.filter = "none";
            return;
        }
        this.ctx.drawImage(image,x_pos,y_pos,width,height);
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx.filter = "none";
        return; 
    }

}


export default drawFrame;