import ycagwyw from './ycagwyw'
import ltsr from './ltsr'
import pp from './pp'
import ec from './ec'

//import test from './default_scene'

const SCENES = {
    "pp": pp,
    "ec": ec,
}

export default SCENES;