import React from 'react';

import ThemeItem from './theme_item'
import VisualiserBTS from './visualiser_bts'
import style from './styles/controller.module.css'
import determineStatus from './controller_status';
import determineDevice from './determine_device';

export default class Controller extends React.Component {
  constructor(props) {
    super(props);
    this.options = React.createRef();
    this.box = React.createRef();
    this.message = React.createRef();
    this.toggle = true;
  }
  componentWillUnmount() {
    if (this.toggle == false) {
      this.toggleButton()
    }
  }

  toggleButton() {
    console.log("Made it here")
    if (this.toggle) {
      this.message.current.className = style.message_fade_in
      this.box.current.className = style.box_fade_in
      this.options.current.className = style.options_fade_in

      this.toggle = false;
    }
    else {
      this.message.current.className = style.message_fade_out
      this.box.current.className = style.box_fade_out
      this.options.current.className = style.options_fade_out

      this.toggle = true;
    }
  };

  makeButton(content) {
    if (content.button_active) {
      return (<div ref={this.button} onClick={this.toggleButton.bind(this)} className={style[`display_button_${content.button_display}`]}></div>)
    }
    else {
      return (
        <div className={style[`display_button_${content.button_display}`]}></div>
      )
    };
  };

  changeVisualiser(id) {
    console.log("callback succeeded")
    return this.props.content.callback(id);
  };

  makeThemes(content) {
      // Step 1 separate current from possible.
      let final = []
      let possible_themes = Object.keys(content.scenes)
      possible_themes.splice(possible_themes.indexOf(content.active_scene),1) // remove current theme
      for (var i = 0; i<possible_themes.length; i++) {
        final.push(<ThemeItem callback={this.changeVisualiser.bind(this)} id={possible_themes[i]} current={false} scenes={content.scenes} />);
      };
      return final;
  };

  makeOptions(content, style) {
    if (content.active_scene != null && content.scenes != null) {
      const active_theme = content.active_scene;
      return (
        <div>
          <VisualiserBTS id={active_theme} scenes={content.scenes} />          
          <div className={style.options_sep}>You might also like</div>
          <div>
            {this.makeThemes(content)}
          </div>
        </div>
      );
    };
    return "";

  };
  
  render () {

    const content = determineStatus(this.props.content);

    const device = determineDevice();
    const device_style = device == "desktop" ? style.global_desktop : style.global_mobile;

    return (
      <div className={device_style}>
        <div ref={this.box} className={style.box}></div>
        <div className={style.display}>
          <div className={style.display_message}>
              <div className={style.display_message_sub}>{content.top}</div>
              <div ref={this.message} className={style.display_message_head}>{content.main}</div>
              <div className={style.display_message_sub}>{content.bottom}</div>
          </div>
          {this.makeButton(content)}
        </div>
        <div ref={this.options} className={style.options}>
          {this.makeOptions(this.props.content, style)}
        </div>
      </div>


    )
  };
}