import React from 'react';
import GLOBALS from '../globals'
import processFrame from './process_frame'
import drawFrame from './draw_frame'
import style from './styles/visualiser.module.css'
import determineDevice from './determine_device';

export default class Visualiser extends React.Component {
  constructor(props) {
    // The rewritten version of visualiser is naive.
    // It assumes that it can start visualising immediately.
    super(props);
    this.canvas = React.createRef()
    this.framerate = 500;
    this.refresh_ms = 1000 / this.framerate;
  }

  componentDidMount() {
    this.startVisualiser();
  };

  componentDidUpdate() {
    this.startVisualiser();
  };

  componentWillUnmount() {
    this.props.content.key = "unmount";
  };

  startVisualiser() {
    this.pF = new processFrame(this.props.content.analysis_object,this.framerate)
    this.dF = new drawFrame(this.props.content.scene_blueprint,this.props.content.scene_resources,this.canvas.current)
    const initProgress = this.props.content.play_object.timestamp;
    this.drawCycle(this.props.content.key, initProgress);
  };

  getCurrentFrame(now, initProgress) {
      const elapsed = now - initProgress;
      //console.log(elapsed)
      const songProgress = this.props.content.play_object.progress_ms;
      const position = songProgress + elapsed;
      this.logSeconds(position,songProgress,elapsed);
      return Math.round(position / this.refresh_ms)
  }

  logSeconds(position,songProgress,elapsed) {
    const pad = (i, size) => {
      var s = String(i);
      while (s.length < (size || 2)) {s = "0" + s;}
      return s;
    }

    const convert = (now) => {
      now = now / 1000
      const min = Math.floor((now - 1) / 60);
      const sec = Math.floor((now - 1)  % 60);
      return `${pad(min,2)}:${pad(sec,2)}`
    };

    const message = `
    calculated : ${convert(position)}
    actual     : ${convert(songProgress)}
    elapsed    : ${Math.round(elapsed/1000)}
    `;

    if (message != this.message) {
      console.log(message);
      this.message = message
    }
  };

  drawCycle(key,initProgress) {
    const current_frame = this.getCurrentFrame(Date.now(),initProgress);
    const cond = current_frame < Math.round(this.props.content.play_object.item.duration_ms / this.refresh_ms) - 50
    if (cond && this.keyCheck(key)) {
      const frame_object = this.pF.process(current_frame);
      this.dF.draw(frame_object,this.props.content.quality_level);
      const helper = () => (this.drawCycle.bind(this))(key,initProgress);
      this.animationFrame = requestAnimationFrame(helper);
    }
    else {
      console.log("Keycheck failed or song ended.")
    }
  };

  keyCheck(key) {
    return key == this.props.content.key;
  }
  
  render () {
    const device = determineDevice();
    const device_style = device == 'desktop' ? style.canvas_desktop : style.canvas_mobile;
    return (
      <div className={style.container}>
        <canvas className={device_style} ref={this.canvas} width={GLOBALS["visualiser_dim"]} height={GLOBALS["visualiser_dim"]}></canvas>
      </div>
    )
  };
}