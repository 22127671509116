import Diplomat from '../diplomat';
import processAnalysis from '../process_analysis';


class globalStream {

    constructor(session_id) {
        this.session_id = session_id;
        this.diplomat = new Diplomat();
        this.pA = new processAnalysis();
        this.state = {
            "visObj": {
                "label": "loading",
            },
            "playObj": {
                "scene_id": "loading",
                "quality_level": "none"
            },
            "analysisObj": null,
            "playback": null,
            "id": null,
        };
    }

    initStream() {
        return this.state;
    }

    listener(func) {
        const f = (function (e) {
            func(e.detail);
        }).bind(this);
        return window.addEventListener('gStream', f)
    };

    pushObj() {
        return (function (newsObj) {
            //console.log(newsObj)
            const newState = {...this.state};
            newState[newsObj.type] = newsObj;
            newState.playback = this.checkPlayback(newState);
            newState.id = this.getID(newState);
            this.suggestChange(newState);
        }).bind(this);
    };

    async suggestChange(newState) {
        if (this.getID(newState) != this.getID(this.state)) {
            this.state = newState;
            if (newState.playback) {
                console.time("Process Analysis");
                newState.analysisObj = await this.prepAnalysis(newState);
                console.timeEnd("Process Analysis");
            };
            const event = new CustomEvent('gStream', {"detail": newState})
            return window.dispatchEvent(event);
        };

    };

    getID(state) {
        const id = `${state.playObj.label}:${state.visObj.scene_id}:${state.visObj.quality_level}`;
        return id;
    };

    checkPlayback(state) {
        const ready = state.playObj.playback && state.visObj.playback;
        return ready;
    };

    async prepAnalysis(newState) {
        const raw = await this.diplomat.getAnalysis(this.session_id,newState.playObj.play_object.item.id);
        const analysis_object = this.pA.process(raw);
        return analysis_object;
    };

};

export default globalStream;