import React from 'react';
import GLOBALS from '../globals'
import style from './styles/visualiser_bts.module.css'

export default class VisualiserBTS extends React.Component {
  constructor(props) {
    super(props);
  }

  changeVisualiser() {
      return this.props.callback(this.props.id)
  }
  
  render () {

    const getThumb = (id) => {
        const src = `${GLOBALS.scene_res}/${id}/${id}_thumb.jpg`;
        return src;
    };

    const id = this.props.id;
    const current = this.props.current; 
    const scenes= this.props.scenes

    const paint = {
        "title": scenes[id].title,
        "description": scenes[id].description,
        "thumb": getThumb(id),
    }

    return (
      <div>
        <div className={style.sub}>Behind the Visualiser</div>
        <div className={style.item}>
            <img src={paint.thumb} className={style.cover}/>
            <div className={style.info}>
              <div className={style.title}>{paint.title}</div>
              <div className={style.description}>{paint.description}</div>
            </div>
        </div>
      </div>
    )
  };
}