import moLib from '../motion_library'
import ryLib from '../rythm_library'

const ml = new moLib()
const rl = new ryLib();

//rl.sceneLock(false, ["drop"])

const c_filter = (f) => {

    let loudness = f.bar.position;
    loudness = Math.round(loudness * 50);
    loudness = loudness + 50

    return `brightness(${loudness}%)`
}



const shadow = (f) => {
    if (f.track.tempo < 100) {
        const rythm_object = [
            [0,1],
            [0,1]
        ]
        const rythm = ml.rythm(f.bar.position,rythm_object)
        return rythm
    }
    else {
        return f.bar.position;
    }
}

const fours = (f) => {
        const rythm_object = [
            [0,1],
            [0,1],
            [0,1],
        ]
        const rythm = ml.rythm(f.bar.position,rythm_object)
        return rythm
}

const burger_opens = (f) => {
    return ml.rythm(f.bar.position, [
        [0,1],
        [0,1],
    ]) * f.segment.current.sigmoid
}


const background = {
    "id": "root",
    "branch": "select",
    "selector": rl.select_loudness,
    "select": [
        {
            "id": "quiet",
            "value": 0.2,
            "branch": "rythm",
            "rythm": [
                {
                    "id": "all",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "1","branch": null},
                        {"id": "2","branch": null},
                        {"id": "3","branch": null},
                        {"id": "4","branch": null},
                    ],
                },
            ],
        },
        {
            "id": "cresc",
            "value": 0.85,
            "branch": "rythm", 
            "rythm": [
                {
                    "id": "all",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "1","branch": null},
                        {"id": "2","branch": null},
                        {"id": "3","branch": null},
                        {"id": "4","branch": null},
                    ],
                },
                {
                    "id": "na",
                    "branch": null,
                },
                {
                    "id": "all",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "2","branch": null},
                        {"id": "3","branch": null},
                        {"id": "4","branch": null},
                        {"id": "1","branch": null},
                    ],
                },
                {
                    "id": "na",
                    "branch": null,
                },
                {
                    "id": "all",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "3","branch": null},
                        {"id": "4","branch": null},
                        {"id": "1","branch": null},
                        {"id": "2","branch": null},
                    ],
                },
                {
                    "id": "na",
                    "branch": null,
                },
            ],
        },
        {
            "id": "na",
            "value": 1,
            "branch": null,
        },
    
    ]
};

const foreground = {
    "id": "root",
    "branch": "select",
    "selector": rl.select_loudness,
    "select": [
        {
            "id": "quiet",
            "value": 0.2,
            "branch": null,
        },
        {
            "id": "cresc",
            "value": 0.85,
            "branch": "rythm", 
            "rythm": [
                {
                    "id": "burger",
                    "branch": "select",
                    "selector": rl.select_loudness,
                    "select": [
                        {"id": "quiet","branch": null,"value": 0.6},
                        {"id": "loud","branch": null,"value": 1},
                    ],
                },
                {
                    "id": "shoe",
                    "branch": "select",
                    "selector": rl.select_loudness,
                    "select": [
                        {"id": "1","branch": null,"value": 0.5},
                        {"id": "2","branch": null,"value": 0.6},
                        {"id": "3","branch": null,"value": 0.7},
                        {"id": "4","branch": null,"value": 1},

                    ],
                },
                {
                    "id": "na",
                    "branch": null,
                },
                {
                    "id": "watch",
                    "branch": "select",
                    "selector": rl.select_loudness,
                    "select": [
                        {"id": "1","branch": null,"value": 0.5},
                        {"id": "2","branch": null,"value": 0.6},
                        {"id": "3","branch": null,"value": 0.7},
                        {"id": "4","branch": null,"value": 1},
                    ],
                },
                {
                    "id": "na",
                    "branch": null,
                },
                {
                    "id": "other",
                    "branch": "rythm",
                    "rythm": [
                        {"id": "1", "branch": null},
                        {"id": "2", "branch": null},
                        {"id": "3", "branch": null},
                    ],
                },
            ],
        },
        {
            "id": "drop",
            "value": 1,
            "branch": "rythm",
            "rythm": [
                {
                    "id": "1",
                    "branch": null,
                },
                {
                    "id": "2",
                    "branch": null,
                }

            ],
        },
    
    ]
};

const ec = {

    "id": "ec",
    "title": "Elephant Art Castle",
    "description": "There was a time before the Elephant and Castle shopping centre fell victim to the intellectually fetishizing gaze of art students. Adapted from a project by Edgar, this visualiser reflects the peculiar state of language used in the art world, and celebrates the shopping centre for what it is. Also, there’s footage of bowling.",
    "pseudo_description": "Dark and robust, this visualiser is packed with zing and undertones of vanilla spice.",    
    "engine": "default",
    "vistruments": [
        {"id": "bowling_1", "dimensions": [1920, 1080], "count": 50, "alpha": false},
        {"id": "bowling_2", "dimensions": [1920, 1080], "count": 50, "alpha": false},
        {"id": "bowling_3", "dimensions": [1920, 1080], "count": 50, "alpha": false},
        {"id": "bowling_4", "dimensions": [1920, 1080], "count": 50, "alpha": false},
        {"id": "bowling_dance", "dimensions": [1920, 1080], "count": 29, "alpha": false},
        {"id": "bowling_reload", "dimensions": [1920, 1080], "count": 50, "alpha": false},
        {"id": "burger", "dimensions": [1195, 1080], "count": 50, "alpha": true},
        {"id": "shoe", "dimensions": [1475, 663], "count": 50, "alpha": true},
        {"id": "sign_1", "dimensions": [1920, 1080], "count": 50, "alpha": false},
        {"id": "sign_2", "dimensions": [1920, 1080], "count": 25, "alpha": false},
        {"id": "toilet", "dimensions": [1920, 1080], "count": 50, "alpha": false},
        {"id": "watch", "dimensions": [641, 723], "count": 50, "alpha": true}
    ],
    "instances": [

        // Background
        // Quiet
        {
            "id": "bowling_1",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["quiet","all","1"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },
        {
            "id": "bowling_2",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["quiet","all","2"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },
        {
            "id": "bowling_3",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["quiet","all","3"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },
        {
            "id": "bowling_4",
            "scale": (f) => 0.5,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["quiet","all","4"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },
        // Cresc
        {
            "id": "bowling_1",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["cresc","all","1"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },
        {
            "id": "bowling_2",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["cresc","all","2"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },
        {
            "id": "bowling_3",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["cresc","all","3"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },
        {
            "id": "bowling_4",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, background, ["cresc","all","4"]),
            "rotate": (f) => null,
            "filter": (f) => c_filter(f),
        },

        // FOREGROUND
        // Cresc
        // Quiet

        // Burger
        {
            "id": "burger",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => burger_opens(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","burger","quiet"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },


        // Loud
        // Burger
        {
            "id": "burger",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [600,500],
            "state": (f) => burger_opens(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","burger","loud"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "burger",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => burger_opens(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","burger","loud"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "burger",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [400,500],
            "state": (f) => burger_opens(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","burger","loud"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        // Shoe
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,740],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,680],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,620],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,560],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["1","2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,440],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,380],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,320],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "shoe",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,260],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","shoe",["4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        // Watch

        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [750,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [700,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [650,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [600,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [550,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["1","2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [450,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [400,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["2","3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [350,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [300,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["3","4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "watch",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [250,500],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc","watch",["4"]]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },


        // DROP

        {
            "id": "sign_1",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop","1"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "sign_2",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => shadow(f),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop","2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

        {
            "id": "watch",
            "scale": (f) => ml.regCycle(f,5) * 2 + 0.5 * ml.smoothSource(f,0,"raw"),
            "opacity": null,
            "position": (f) => [550,470],
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["drop"]),
            "rotate": (f) => ml.rotInit(0, ml.smoothSource(f,1,"raw") / 2),
            "filter": (f) => null,
        },



        // OTHER

        {
            "id": "bowling_dance",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "other", "1"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "bowling_reload",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "other", "2"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },
        {
            "id": "toilet",
            "scale": (f) => 1,
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => f.bar.position,
            "store": (f) => null,
            "hide": (f) => rl.hide(f, foreground, ["cresc", "other", "3"]),
            "rotate": (f) => null,
            "filter": (f) => null,
        },

    ]
}

export default ec;