
//const BASE = "http://localhost"
const BASE = "https://tingles.wtf"
//const BASE = "https://unpredictable-tingles.appspot.com"


const GLOBALS = {
    "base": BASE,
    "getAccess": `${BASE}/access`,
    "refreshAccess": `${BASE}/refresh`,
    "callback": `${BASE}/callback`,
    "play": `${BASE}/play`,
    "analysis": `${BASE}/analysis`,
    "scope": "user-read-playback-state",
    "client_id": "64dc44cc49d1464cbde97c06594f7a80",
    "scene_res": "https://storage.googleapis.com/resources-unpredictable-tingles/scenes",
    "quality_levels": [
        {
        "name": "LOAD",
        "quot": 20,
        },
        {
        "name": "500",
        "quot": 4,
        },
        {
            "name": "1000",
            "quot": 2,
        },
    ],
    "visualiser_dim": 2000,
}

/*

        {
        "name": "2000",
        "quot": 1,
        },
*/

export default GLOBALS;