import moLib from '../motion_library'

const ml = new moLib()

class spec {
    // These are not designed to be reused outside of a group of instances;
    static zingyPos(f,init,mode) {
        // current position
        const track_tempo = ml.reverse(ml.mapTempo(f.track.tempo))
        const duration = 10 + 15 * (track_tempo); 
        const value = ml.regCycle(f,duration);
        const cycles = [
            value,
            ml.rythm(value, [[0.5,1],[0,0.5]]),
            ml.rythm(value, [[0.75,1],[0,0.25],[0.25,0.5],[0.5,0.75]]),
            ml.rythm(value, [[0.25,0.5],[0.5,0.75],[0.75,1],[0,0.25]])
        ];
        const p = cycles[mode];
        const angle = 30 + f.segment.current.loudness_stable * 30;
        const dimensions = [1000,500];
        const coordinates = ml.carousel(p,angle,dimensions);
        const relative_init = [
            dimensions[0] / 2 - init[0],
            dimensions[1] / 2 - init[1]
        ];
        return [coordinates[0]+relative_init[0],coordinates[1]+relative_init[1]];
    };

    static bannerStore(f) {
        const track_tempo = ml.reverse(ml.mapTempo(f.track.tempo))
        const tempo = ml.sectionSmooth(f)
        const duration = 15 + 20 * (tempo * track_tempo)
        const func = ml.memVelocity
        const args = {
            duration: duration
        }
        return ml.memoryBind(f,func,args,"store")
    };

    static bannerPos(f,init,mode) {

        const track_tempo = ml.reverse(ml.mapTempo(f.track.tempo))
        const duration = 20 + 15 * (track_tempo); 
        const value = ml.regCycle(f,duration);
        const cycles = [
            value,
            ml.rythm(value, [[0.5,1],[0,0.5]]),
            ml.rythm(value, [[0.75,1],[0,0.25],[0.25,0.5],[0.5,0.75]]),
            ml.rythm(value, [[0.25,0.5],[0.5,0.75],[0.75,1],[0,0.25]])
        ];
        const p = cycles[mode];
        const angle = 89;
        const dimensions = [500,1000];
        const coordinates = ml.carousel(p,angle,dimensions);
        const relative_init = [
            dimensions[0] / 2 - init[0],
            dimensions[1] / 2 - init[1]
        ];
        return [coordinates[0]+relative_init[0],coordinates[1]+relative_init[1]];
    };

    static quietLoud(f,range) {

        const ranges = {
            "drop": [0.85,1],
            "omni": [0,0.85],
            "build": [0.15,0.85],
        }
        const loudness = f.segment.current.sigmoid
        if (loudness >= ranges[range][0] && loudness <= ranges[range][1]) {
            return false;
        }
        return true
    }



};


const ycagwyw = {

    "id": "ycagwyw",
    "title": "You Can't Always Get What You Want",
    "description": "Dark and robust, this visualiser is packed with zing and undertones of vanilla spice. Great with peppered steak.",
    "engine": "default",
    "vistruments": [
        {
            "id": "tangerine",
            "dimensions": [500,500],
            "count": 49,
            "mask": true
        },
        {
            "id": "hand",
            "dimensions": [960,540],
            "count": 60,
            "mask": true
        },
        {
            "id": "banner_1",
            "dimensions": [500,1000],
            "count": 50,
            "mask": false
        },
        {
            "id": "banner_2",
            "dimensions": [500,1000],
            "count": 50,
            "mask": false
        },
        {
            "id": "banner_3",
            "dimensions": [500,1000],
            "count": 50,
            "mask": false
        },
        {
            "id": "banner_4",
            "dimensions": [500,1000],
            "count": 50,
            "mask": false
        }
    ],
    "instances": [


        // BANNERS
        {
            "id": "banner_1",
            "scale": (f) => 1.3,
            "opacity": null,
            "position": (f) => spec.bannerPos(f,[250,500],0),
            "state": (f) => ml.pow(f.tatum.position) * f.segment.current.loudness_stable,
            "store": (f) => spec.bannerStore(f),
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => null
        },
        {
            "id": "banner_2",
            "scale": (f) => 1.3,
            "opacity": null,
            "position": (f) => spec.bannerPos(f,[250,500],1),
            "state": (f) => ml.pow(f.tatum.position) * f.segment.current.loudness_stable,
            "store": (f) => spec.bannerStore(f),
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => null
        },
        {
            "id": "banner_3",
            "scale": (f) => 1.3,
            "opacity": null,
            "position": (f) => spec.bannerPos(f,[250,500],2),
            "state": (f) => ml.pow(f.tatum.position) * f.segment.current.loudness_stable,
            "store": (f) => spec.bannerStore(f),
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => null
        },
        {
            "id": "banner_4",
            "scale": (f) => 1.3,
            "opacity": null,
            "position": (f) => spec.bannerPos(f,[250,500],3),
            "state": (f) => ml.pow(f.tatum.position) * f.segment.current.loudness_stable,
            "store": (f) => spec.bannerStore(f),
            "hide": (f) => spec.quietLoud(f,"omni"),
            "rotate": (f) => null
        },

        // TANGERINES

        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,0,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[166,300],0),
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,1,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,1,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[500,250],0),
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,1,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[833,200],0),
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,1,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[166,300],1),
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,0,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[500,250],1),
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,2,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[833,200],1),
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,1,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,0,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[166,300],2),
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,0,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,1,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[500,250],2),
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,2,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[833,200],2),
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,0,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[166,300],3),
            "state": (f) => ml.smoothSource(f,0,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,1,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,1,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[500,250],3),
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,1,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.2 + 0.2 * ml.smoothSource(f,0,"raw"),
            "opacity": null,
            "position": (f) => spec.zingyPos(f,[833,200],3),
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"build"),
            "rotate": (f) => 0.4 * ml.smoothSource(f,1,"raw")
        },


        // HAND

        {
            "id": "hand",
            "scale": (frame_object) => 1.1,
            "opacity": null,
            "position": (frame_object) => [720,510],
            "state": (f) => {
                if (f.track.tempo < 140) {
                    const rythm_object = [
                        [0,1],
                        [0,1]
                    ]
                    const rythm = ml.rythm(f.bar.position,rythm_object)
                    return rythm
                }
                else {
                    return f.bar.position;
                }

            },
            "store": (f) => null,
            "hide": (f) => {
                const ql = spec.quietLoud(f,"build");
                const confident = f.bar.current.confidence < 0.4 ? true : false;
                //console.log(f.bar.current.confidence)
                const cond = ql == true || confident == true;
                return cond;
            },
            "rotate": (f) => -0.08 * ml.smoothSource(f,1,"raw")
        },

        // DROP
    
        {
            "id": "banner_1",
            "scale": (f) => 2 + 0.2 * ml.pow(f.tatum.position),
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.pow(f.tatum.position) * f.segment.current.loudness_stable,
            "store": (f) => spec.bannerStore(f),
            "hide": (f) => spec.quietLoud(f,"drop"),
            "rotate": (f) => null
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.4 + 0.4 * ml.smoothSource(f,0,"raw"),
            "opacity": null,
            "position": (f) => [250,500],
            "state": (f) => ml.smoothSource(f,2,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"drop"),
            "rotate": (f) => 0.2 * ml.smoothSource(f,1,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.4 + 0.4 * ml.smoothSource(f,2,"raw"),
            "opacity": null,
            "position": (f) => [750,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"drop"),
            "rotate": (f) => 0.2 * ml.smoothSource(f,0,"raw")
        },
        {
            "id": "tangerine",
            "scale": (f) => 0.5 + 0.5 * ml.smoothSource(f,0,"raw"),
            "opacity": null,
            "position": (f) => [500,500],
            "state": (f) => ml.smoothSource(f,1,"raw"),
            "store": (f) => null,
            "hide": (f) => spec.quietLoud(f,"drop"),
            "rotate": (f) => 0.2 * ml.smoothSource(f,2,"raw")
        },
    ]
}

export default ycagwyw;