
class moLib {

    // takes normalised position cycle values
    // returns normalised position cycle values

    pow(pos,e = 3) {
        return Math.pow(pos,e)
    }

    reverse(pos) {
        return 1 - pos
    }

    log(pos) {
        return Math.log2(pos + 1)
    }

    rythm(pos,rythm_object) {
        const length = rythm_object.length;
        const beat_pos = (pos % (1 / length)) * length;
        let beat = Math.floor((pos*1000) / ((1 / length)*1000))
        beat = beat == length ? length -1 : beat;

        const start = rythm_object[beat][0]
        const end = rythm_object[beat][1]

        return start + (beat_pos * (end - start))
    }

    memoryBind(f, func=null, args=null, key) {
        
        if (key == "store") {
            // Reading from store
            // Writing to store
            return func(f,args,f.memory);

        } 
        else {
            // Reading from store 
            // Writing to position or anywhere else
            return f.memory
        }
    }

    memVelocity(f,args,memory) {
        
        // Takes duration, maps it to relative progress in cycle

        // Step 1: Determine velocity
        const dur_frames = args.duration * f.framerate; // Duration of cycle in frames | > 1 
        const length = 1; // Distance to traverse in one cycle
        const velocity = length / dur_frames; 

        if (memory == null || memory >= 1) {
            return 0;
        }
        else {
            return memory + velocity;
        }
    };

    regCycle(f,duration) {
        const current = f.current_frame;
        const cycle = duration * f.framerate; // Duration of cycle in frames | > 1 
        return (current % cycle) / cycle
    }

    carousel(progress,angle,dimensions) {
        // Takes angle, dimensions, init and progress
        // Step 1: Determine relative position of x and y => [0,1]
        const deg = (degrees => degrees * Math.PI / 180)(angle)
        const slope = {
            x: Math.sin(deg) > Math.cos(deg) ? 1 : Math.sin(deg) / Math.cos(deg),
            y: Math.cos(deg) > Math.sin(deg) ? 1 : Math.cos(deg) / Math.sin(deg)
        };
        const relativePos = {
                x: slope.x * progress + (1 - slope.x) / 2,
                y: slope.y * progress + (1 - slope.y) / 2
        };
        const span = Math.sqrt(Math.pow(dimensions[0],2) + Math.pow(dimensions[1],2)); // Should be the hypotenuse of the two ? 

        const actualPos = [
            (relativePos.x * span * 2) - span / 2,
            (relativePos.y * span * 2) - span / 2
        ];

        return actualPos;
    };

    sectionSmooth(f,attr="tempo") {
        // Only works with normalized values, duh.

        const current = f.section.current[attr]
        const next = f.section.next[attr]
        const slope = next - current
        const pos = f.section.position
        const gradient = x => 0.5 + (slope / 2) * x
        const bezier_object = [
            {x: 0, y: gradient(0)},
            {x: 0.1, y: 0.5},
            {x: 0.9, y: 0.5},
            {x: 0, y: gradient(1)},
        ]
        const bezier = this.bezier(pos,bezier_object)
        return bezier
    }
  
    smoothSource(f,i,type) {
        const current = f.sources.current[type][i]
        const pos = f.sources.position
        const next = f.sources.next[type][i]
        const slope = next - current
        const gradient = x => current + slope * x
        //const helper = x => 2 * (Math.pow(x,2) - x + 0.5)
        const wobble = x => (0.5 * (1 - Math.abs(slope))) * Math.sin(2 * Math.PI * x) + 0.5
        return gradient(pos) * 1 // + wobble(pos) * 0.2

    }   

    mapTempo(bpm) {
        // a reasonable tempo range is between 80 and 160 bpm
        // to be conservative, lets assume a track is between 60 and 180 bpm
        let value = (bpm - 60) / 120;
        value = value > 1 ? 1 : value; 
        value = value < 0 ? 0 : value; 
        return value;
    }

    bezier (pos, bezier_object) {
        let p0 = bezier_object[0]
        let p1 = bezier_object[1]
        let p2 = bezier_object[2]
        let p3 = bezier_object[3]
        
        var cX = 3 * (p1.x - p0.x),
            bX = 3 * (p2.x - p1.x) - cX,
            aX = p3.x - p0.x - cX - bX;
      
        var cY = 3 * (p1.y - p0.y),
            bY = 3 * (p2.y - p1.y) - cY,
            aY = p3.y - p0.y - cY - bY;
      
        // var x = (aX * Math.pow(t, 3)) + (bX * Math.pow(t, 2)) + (cX * t) + p0.x;
        var y = (aY * Math.pow(pos, 3)) + (bY * Math.pow(pos, 2)) + (cY * pos) + p0.y;
      
        return y;
      };

      rotInit(init, value) {
          const new_value = init + value > 1 ? init + value - 1 : init + value;
          return new_value;
      };

}


export default moLib;