
class ryLib {

    constructor() {
        this.sceneLockObj = {
            active: false,
        }
    }

    match_rythm(rythm,index) {
        const match_rythm_it = (rythm,index,mod,array) => {
            const index_temp = Math.floor(index / mod);
            mod = mod * rythm.length;
            const beat = index_temp % rythm.length
            array.push(rythm[beat].id)
            if (rythm[beat].branch == "rythm") {
                return match_rythm_it(rythm[beat].rythm,index,mod,array)
            };
            return [rythm[beat],array];

        };
        return match_rythm_it(rythm,index, 1,[])
    };

    match_rythm_obsolete(rythm,index) {
        return rythm[index % rythm.length];
    }

    select_loudness(f,select) {
        const loudness = f.segment.current.sigmoid;
        for (var i = 0; i < select.length; i++) {
            if (loudness <= select[i].value) {
                return select[i];
            }
        };
    };


    leaf(f,tree) {
        const crawl_tree_it = (f,tree,leafarray) => {
            let current_leaf
            switch (tree.branch) {
                case null:
                    return leafarray;
                case "rythm":
                    const index = f.bar.counter[0];
                    current_leaf = this.match_rythm(tree.rythm,index);
                    leafarray = [...leafarray, ...current_leaf[1]]
                    //console.log(current_leaf[0])
                    //leafarray.push(current_leaf.id)
                    return crawl_tree_it(f,current_leaf[0],leafarray);
                case "select":
                    current_leaf = tree.selector(f,tree.select)
                    leafarray.push(current_leaf.id);
                    return crawl_tree_it(f,current_leaf,leafarray);
            }
        }

        let leafarray = crawl_tree_it(f,tree,[])
        //console.log(leafarray)
        //leafarray.shift(); // remove superfluous "root" identifier from array
        return leafarray;
    };

    leafMatch(leafarray,current_leafarray) {
        for (var i = 0; i < current_leafarray.length; i++) {
            if (i < leafarray.length) {
                leafarray[i] = Array.isArray(leafarray[i]) ? leafarray[i] : [leafarray[i]];
                if (leafarray[i].includes(current_leafarray[i]) == false) {
                    return false;
                };
            };
        };
        return true;
    };

    sceneLock(active, leafarray) {
        this.sceneLockObj = {
            "active": active,
            "leafarray": leafarray,
        };
    };

    hide(f,layer,leafarray) {

        let current_leafarray;

        if (this.sceneLockObj.active) {
            current_leafarray = this.sceneLockObj.leafarray;
        }
        else {
            current_leafarray = this.leaf(f,layer); 
        };

        let match = this.leafMatch(leafarray,current_leafarray);
        if (match) {
            return false;
        }
        else {
            return true;
        };
    };


 
};
export default ryLib;