
import GLOBALS from '../globals'
// `${GLOBALS.scene_res}/${default_scene.id}`

async function getFrames(scene,ql) {

    let source_array = []
    const vistruments = scene.vistruments

    for (var a = 0; a < vistruments.length; a++) {
        for (var b = 0; b < vistruments[a].count; b++) {
            const ql_name = GLOBALS["quality_levels"][ql]["name"]
            const loc = `${GLOBALS.scene_res}/${scene.id}/${ql_name}/${vistruments[a].id}`;
            const alpha = vistruments[a].alpha
            const ext = alpha ? "png" : "jpg";
            const file = `f_${b+1}.${ext}`;
            const src = `${loc}/${file}`;
            const id = `${scene.id}_${vistruments[a].id}_${b}`
            const obj = {
                "src": src,
                "id": id,
            }            
            source_array.push(obj)
        }
    }

    const prep = async() => Promise.all(source_array.map(async obj=>{

        let headers = new Headers({
            "Origin": GLOBALS["base"]
        });
        let response = await fetch(obj.src,{"headers": headers})
        return {
            "obj": obj,
            "response": response
        }

    }))
    const responses = await prep()

    //console.log("start")
   
    for (var i = 0; i < responses.length; i++) {
        const loaded = (img) => {
            return new Promise((resolve) => {
                img.onload = () => resolve()
            })
        }
        let response = responses[i]["response"];
        let obj = responses[i]["obj"];

        let blob = await response.blob()
        let img = new Image()
        img.src = URL.createObjectURL(blob)
        img.id = obj.id
        await loaded(img);
        responses[i] = img;
    }

    //console.log("finish")

    return responses;

  }
  
  export default getFrames;