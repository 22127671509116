
import Diplomat from '../diplomat';

class playbackStream {

    constructor(session_id) {
        this.session_id = session_id;
        this.diplomat = new Diplomat();
    }

    listener(func) {
        const f = (function (e) {
            func(e.detail);
        }).bind(this);
        return window.addEventListener('pStream', f)
    };

    startStream() {
        this.Stream();
        const playObj = {
            "type": "playObj",
            "playback": false,
            "label": "loading",
            "play_object": null,
        };

        const event = new CustomEvent('pStream', {"detail": playObj});
        return window.dispatchEvent(event);      
    };

    async Stream() {
        const refresh_ms = 2000;
        setTimeout(async() => {
            this.Stream();

            let timestamp = Date.now();
            const play_object = await this.diplomat.getPlay(this.session_id);
            timestamp = timestamp + ((Date.now() - timestamp) / 2);

            const playObj = this.readPlayData(play_object,timestamp);
            const event = new CustomEvent('pStream', {"detail": playObj});
            return window.dispatchEvent(event);     
        },refresh_ms);
    };

    readPlayData(play_object,timestamp) {
        const playObj = {
            "type": "playObj",
            "playback": null,
            "label": null,
            "play_object": null,
        };

        playObj.play_object = play_object;
        //console.log(play_object)

        if (play_object === "no_session") {
            playObj.label = "no_session";
            playObj.playback = false;
        }
        else if (play_object === "error_occurred") {
            playObj.label = "error_occurred";
            playObj.playback = false;          
        }
        else if (play_object.is_playing && play_object.item != null) {
            playObj.label = play_object.item.id;
            playObj.play_object.timestamp = timestamp;
            playObj.playback = true;
        }
        else if (play_object.is_playing === false && play_object.item != null) {
            playObj.label = "song_paused";
            playObj.playback = false;
        }
        else {
            playObj.label = "error_occurred";
            playObj.playback = false;
        };

        return playObj;
    };



};

export default playbackStream;