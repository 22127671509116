import React from 'react';
import logo from './logo.svg';
import Login from './components/login';
import Running from './components/running';
import './components/styles/main.css';
import style from './components/styles/backdrop.module.css';


export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    this.route = this.determineRoute() 
    if (this.route != null) {
      return (
        <div>
          <Running style={this.css} session_id={this.route.session_id}></Running>
          <div className={style.backdrop}></div>
        </div>

      )
    }
    return (
      <div>
        <Login></Login>
        <div className={style.backdrop}></div>
      </div>

    )
  };

  determineRoute() {
    // Will have to add support for cookies here
    const query = new URLSearchParams(window.location.search)
    if(query.has("session_id") && query.has("dev")) {
      return {"session_id": query.get("session_id"), "dev": true}
    }
    if (query.has("session_id")) {
      const session_id = query.get("session_id")
      return {"session_id": session_id, "dev": false}
    }
    return null
  }

}

