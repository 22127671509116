
class processFrame {

    constructor(analysis_object,framerate) {
      console.log(analysis_object)
      this.song = analysis_object.track;
      this.channels = {
        "bars": analysis_object.bars,
        "beats": analysis_object.tatums,
        "tatums": analysis_object.tatums,
        "sections": analysis_object.sections,
        "segments": analysis_object.segments,
        "track": analysis_object.track,
        "sources": analysis_object.sources
      }
      this.framerate = framerate
      
    }

    process(current_frame) {
      // returns frameObject
      const frameObject = {
        "bar": this.locateEvents(current_frame,"bars"),
        "beat": this.locateEvents(current_frame,"beats"),
        "tatum": this.locateEvents(current_frame, "tatums"),
        "section": this.locateEvents(current_frame, "sections"),
        "segment": this.locateEvents(current_frame, "segments"),
        "sources": this.locateEvents(current_frame, "sources"),
        "track": this.channels.track,
        "current_frame": current_frame,
        "framerate": this.framerate,
      }
      return frameObject;
    }

    locateEvents(current_frame,channel) {
      // Returns the current and next event
      // And the position between them
      const items = this.channels[channel]
      const cond = item => this.secToFrame(item.start) > current_frame
      const next_index = items.findIndex(cond)

      let current_event;
      let next_event;

      if (next_index == 0) {
        // Channel hasn't started
        current_event = items[0]
        current_event.start = 0;
        current_event.duration = items[0].start;
        current_event.confidence = 0.5;
        next_event = items[0]
      }
      else if (next_index == -1) {
        // Channel is about to end
        current_event = items[items.length-1]
        next_event = items[items.length-1];
        next_event.start = current_event.start+current_event.duration;
        next_event.duration = 0;
        next_event.confidence = 0.5;
      }
      else {
        // Channel in progress
        current_event = items[next_index-1]
        next_event = items[next_index]
      };

      const start_frame = this.secToFrame(current_event.start);
      const duration_frame = this.secToFrame(current_event.duration);
      let position = duration_frame == 0 ? 0 : (current_frame-start_frame)/duration_frame;
      position = position > 1 ? 0 : position; 

      return {
        "current": current_event,
        "next": next_event,
        "position": position,
        "counter": [next_index == -1 ? items.length : next_index,items.length],
      }

    }

    secToFrame(s) {
      return Math.round(s*this.framerate)
    }



}


export default processFrame;